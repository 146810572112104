import React from 'react';
import { graphql } from 'gatsby';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import Seo, { OG_PAGE_TYPES } from 'common/Seo';
import StructuredPageSchema from 'common/StructuredPageSchema';
import AccordionItems from 'components/AccordionItems';
import Banner from 'components/Banner';
import ProductDetailsBanner from 'components/ProductDetailsBanner';
import ProductFeatures from 'components/ProductFeatures';
import RecommendedProducts from 'components/RecommendedProducts';
import { sortRecommendedProducts } from 'utils/productsSorting';
import { schemaService } from 'utils/schemaService';

const ProductDetails = ({
  data: {
    productDetails: {
      urls,
      seo,
      accordionItems,
      productFeatures,
      productDetailsBanner,
      title: productTitle,
      productImage,
      descriptionLong,
      recommendedProducts: { recommendedTitle, products: recommendedProductsOrder },
      videoBanner,
    },
    allRecommendedProducts: { nodes: allRecommendedProductsNodes },
    allProductDetails,
    siteSettings,
    brandSettings,
    allFooter,
  },
  pageContext: {
    breadcrumb: { crumbs, location },
    lang,
  },
}: ProductDetailsTypes.ProductDetailsProps) => {
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };
  const { customButton, retailersLink, categoryLinks } = productDetailsBanner || {};
  const schemaData = schemaService.extractSchemaData({
    seo,
    siteUrl: siteSettings.siteUrl,
    pageUrl: location || '/',
    siteSettings,
    brandSettings,
    lang: siteSettings.lang,
    socialNetworks: allFooter?.nodes?.[0]?.socialMediaLinks,
    schemaImageUrl: productImage,
  });

  return (
    <Layout {...{ crumbsSettings }} headerTransparent>
      <Seo
        {...{
          lang,
          urls,
          seo: { ...seo, openGraphType: seo.openGraphType || OG_PAGE_TYPES.PRODUCT },
        }}
      />
      <StructuredPageSchema additionalSchema="Product" schemaData={schemaData} />
      <ProductDetailsBanner
        {...{
          customButton,
          retailersLink,
          categoryLinks,
          productTitle,
          productImage,
          descriptionLong,
        }}
      />
      {productFeatures ? (
        <ProductFeatures
          {...{
            iconList: productFeatures.iconList,
            iconsGroupTitle: productFeatures.iconsGroupTitle,
          }}
        />
      ) : null}
      <Container>
        <AccordionItems {...{ accordionItems, allProductDetails }} />
      </Container>
      <RecommendedProducts
        {...{
          allRecommendedProductsNodes: sortRecommendedProducts(
            recommendedProductsOrder,
            allRecommendedProductsNodes
          ),
          recommendedTitle,
        }}
      />
      {videoBanner ? <Banner {...videoBanner} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $id: String, $recommendedProductsSku: [String]) {
    productDetails(lang: { eq: $lang }, id: { eq: $id }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      accordionItems {
        ...AccordionItemType
      }
      productFeatures {
        ...ProductFeaturesType
      }
      productDetailsBanner {
        ...ProductDetailsBannerType
      }
      recommendedProducts {
        recommendedTitle
        products {
          value
        }
      }
      sku
      title
      productImage {
        ...SalsifyImageType
      }
      videoBanner {
        ...BannerType
      }
      descriptionLong
    }
    siteSettings {
      siteUrl
      siteName
      lang
    }
    brandSettings {
      brandName
      brandLogo {
        ...UmbracoImage
      }
    }
    allFooter {
      nodes {
        socialMediaLinks {
          ...SocialMediaLinkType
        }
      }
    }
    allRecommendedProducts: allProductDetails(filter: { sku: { in: $recommendedProductsSku } }) {
      nodes {
        title
        productImage {
          ...SalsifyImageType
        }
        url
        sku
      }
    }
    allProductDetails {
      nodes {
        title
        productImage {
          ...SalsifyImageType
        }
        sku
        url
      }
    }
  }
`;

export const ListingType = graphql`
  fragment ProductDetailsType on ProductDetailsConnection {
    nodes {
      url
      createdAt
      title
      descriptionLong
      descriptionShort
      productImage {
        ...SalsifyImageType
      }
      tags {
        ...TagType
      }
      filterByKeywords {
        value
      }
      filterByProductRange
      filterByMessType
      filterByFormat
    }
  }
`;

export default ProductDetails;
