import React from 'react';

import AccordionItem from './AccordionItem';

import './AccordionItems.scss';

const AccordionItems = ({ accordionItems, allProductDetails }: PageContent.AccordionItemsType) => (
  <div className="accordion-items" data-testid="accordion-items">
    {accordionItems.map((accordionItem) => (
      <AccordionItem key={accordionItem.title} {...{ ...accordionItem, allProductDetails }} />
    ))}
  </div>
);

export default AccordionItems;
