import React from 'react';

import ProductsCarousel from 'components/ProductsCarousel';

import './RecommendedProducts.scss';

const RecommendedProducts = ({
  allRecommendedProductsNodes,
  recommendedTitle,
}: PageContent.RecommendedProductsType) => (
  <div className="recommended-product" data-testid="recommended-product" id="main-content">
    <p className="recommended-product__main-title">{recommendedTitle}</p>
    <ProductsCarousel products={allRecommendedProductsNodes} />
  </div>
);

export default RecommendedProducts;
