import React from 'react';
import { Link } from 'gatsby';

import CustomCarousel from 'common/CustomCarousel';
import Image from 'common/Image';
import { RELATED_CAROUSEL_SETTINGS } from 'utils/constants';

import './ProductsCarousel.scss';

const ProductsCarousel = ({ products }: PageContent.ProductsCarouselType) => (
  <div className="products-carousel" data-testid="products-carousel">
    <CustomCarousel settings={RELATED_CAROUSEL_SETTINGS}>
      {products?.map(({ title, productImage: { salsifyImage, altText }, url }) => (
        <Link to={url} key={title}>
          <Image imageData={salsifyImage} alt={altText} />
          <h4 className="products-carousel__title">{title}</h4>
        </Link>
      ))}
    </CustomCarousel>
  </div>
);

export default ProductsCarousel;
