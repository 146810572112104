import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import CustomLink from 'common/CustomLink';
import SVG from 'common/Icon/SVG';
import Image from 'common/Image';

import './AccordionItem.scss';

const ArrowIcon = SVG['accordion-arrow'];

const AccordionItem = ({
  title,
  accordionContent,
  products,
  description,
  initialOpen = false,
  allProductDetails,
}: PageContent.AccordionItemType) => {
  const [open, setOpen] = useState(initialOpen);
  const getImage = (sku) =>
    allProductDetails.nodes.find((product) => product.sku === sku)?.productImage;
  const productsWithImg = products?.map((product) => ({
    ...product,
    productImage: getImage(product.sku),
  }));

  return (
    <div
      className={classNames('accordion', {
        'accordion--open': open,
      })}
      data-testid="accordion-item"
    >
      <div
        role="button"
        tabIndex={0}
        onClick={() => setOpen(!open)}
        onKeyDown={() => setOpen(!open)}
        className="accordion__title"
      >
        <DangerouslySetInnerHtml html={`<h3>${title}</h3>`} className="accordion__title-text" />
        <ArrowIcon />
      </div>
      <div className="accordion__content">
        {description ? (
          <DangerouslySetInnerHtml html={description} className="accordion__description" />
        ) : null}
        {products ? (
          <div className="accordion__products">
            {productsWithImg?.map(
              ({
                productImage,
                productTitle,
                productDescription,
                productLink: { linkLabel, color, link, isLinkExternal },
              }) => (
                <div key={productTitle} className="accordion__products-item">
                  <Image
                    imageData={productImage.salsifyImage}
                    alt={productImage.altText}
                    className="accordion__products-image"
                  />
                  <h4 className="accordion__products-title">{productTitle}</h4>
                  <DangerouslySetInnerHtml
                    html={productDescription}
                    className="accordion__products-description"
                  />
                  <CustomLink
                    linkLabel={linkLabel}
                    color={color}
                    link={link}
                    isLinkExternal={isLinkExternal}
                    className="accordion__products-link"
                  />
                </div>
              )
            )}
          </div>
        ) : null}
        {accordionContent?.map((accordionItem) => (
          <>
            {accordionItem.link && accordionItem.image ? (
              <Link
                key={accordionItem.link.name}
                className="accordion__link"
                aria-label={accordionItem.link.name}
                to={accordionItem.link.url}
              >
                <Image imageData={accordionItem.image} alt={accordionItem.image.altText} />
              </Link>
            ) : null}
            {accordionItem.image && !accordionItem.link ? (
              <Image
                key={accordionItem.image.altText}
                imageData={accordionItem.image}
                alt={accordionItem.image.altText}
              />
            ) : null}
          </>
        ))}
      </div>
    </div>
  );
};

export const AccordionItemType = graphql`
  fragment AccordionItemType on AccordionItemType {
    title
    description
    products {
      productTitle
      productDescription
      productLink {
        ...CustomLinkType
      }
      sku
    }
    accordionContent {
      link {
        ...LinkType
      }
      image {
        ...UmbracoImage
      }
    }
  }
`;

export default AccordionItem;
