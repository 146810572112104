import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Container from 'layout/Container';
import IconList from 'common/IconList';

import './ProductFeatures.scss';

const ProductFeatures = ({ iconList, iconsGroupTitle }: PageContent.ProductFeaturesType) => (
  <section
    className={classNames('background-pink product-features', {
      'product-features--two-columns': iconList.length === 4,
    })}
    data-testid="product-features"
  >
    <Container>
      <h2 className="product-features__title">{iconsGroupTitle}</h2>
      <IconList {...{ iconList }} />
    </Container>
  </section>
);

export const ProductFeaturesType = graphql`
  fragment ProductFeaturesType on ProductFeaturesType {
    iconsGroupTitle
    iconList {
      ...IconListType
    }
  }
`;

export default ProductFeatures;
