import React from 'react';
import { Link, navigate } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import CustomButton from 'common/CustomButton';
import Image from 'common/Image';

import './ProductDetailsBanner.scss';

const ProductDetailsBanner = ({
  customButton,
  retailersLink,
  categoryLinks,
  productTitle,
  descriptionLong,
  productImage,
}: PageContent.ProductDetailsBannerType) => {
  const { ariaLabel, buttonLabel, buttonLink, backgroundColor } = customButton || {};
  const { salsifyImage, altText } = productImage;

  return (
    <div className="product-details-banner__wrapper" data-testid="product-details-banner">
      <div className="product-details-banner">
        <Image imageData={salsifyImage} alt={altText} />
        <div className="product-details-banner__content">
          {categoryLinks?.map((categoryLink) => {
            const { url, name } = categoryLink;

            return (
              <Link to={url} className="product-details-banner__link" key={name}>
                {name}
              </Link>
            );
          })}
          <h1 className="product-details-banner__title">{productTitle}</h1>
          <DangerouslySetInnerHtml
            html={descriptionLong}
            className="product-details-banner__description"
          />
          <CustomButton
            {...{ ariaLabel, backgroundColor, buttonLabel, buttonLink }}
            className="product-details-banner__btn"
            onClick={() => navigate(buttonLink?.url!)}
          />
          {retailersLink ? (
            <Link to={retailersLink.url} className="product-details-banner__retailers">
              {retailersLink.name}
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsBanner;
