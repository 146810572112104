import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { ISchemaData, schemaService, SchemaTypes } from 'utils/schemaService';

import { IStructuredPageSchemaProps } from './model';

const StructuredPageSchema: FC<IStructuredPageSchemaProps<ISchemaData>> = ({
  additionalSchema,
  schemaData,
}) => {
  const schemas: SchemaTypes[] = ['WebPage'];
  if (additionalSchema) {
    schemas.push(additionalSchema);
  }

  const structuredSchemaScripts = schemaService.getSchemas(schemas, schemaData);

  return <Helmet script={structuredSchemaScripts} />;
};

export default StructuredPageSchema;
